// Copyright 2021 5 Academy
export const Style = {
  marginOffset: (numOffSet,marginType='marginTop',strUnits='px') => {
    return {
      [marginType]: `${numOffSet}${strUnits}`
    };
  },
  //@ts-ignore
  zIndex: (numIndx) => {
    return (numIndx) ? {
      position: 'relative',
      zIndex: numIndx
    } : {};
  }
};