// Copyright 2021 5 Academy
import React from 'react';
import { Style } from '../../../../utils/style';

const Z_INDEX_IMAGE = 5;

const PricingMatrixRow = ({ strRowTitle, strRowDescr, plusElm, bundleElm, packElm }) => {
  return (
    <tr>
      <th scope="row" className="font-weight-normal">
        <span className="font-weight-normal text-decoration-underline">
          {strRowTitle}
        </span>
        <br />
        <span className="text-xs">{strRowDescr}</span>
      </th>
      <td className="font-weight-bold">
        {plusElm}
      </td>
      <td>{bundleElm}</td>
      <td>{packElm}</td>
    </tr>
  );
};
  
const PricingMatrix = () => {
  return (
    <div style={Style.marginOffset(0)}>
      <div className="d-flex flex-row-reverse">
        <img src="/img/la_pge/obj/cube.png" width="200px" alt="3d cube" style={{ ...Style.marginOffset(-100), ...Style.marginOffset(-110,'marginRight'), ...Style.zIndex(Z_INDEX_IMAGE) }}/>
      </div>
      <h1 className="display-4 mb-4 pb-4">
        <span className="font-weight-bold">Feature</span>
        <span className="font-weight-lighter"> Breakdown</span>
      </h1>
      <table className="table table-primary-2 bg-pastel-background text-pastel-black">
        <thead>
          <tr>
            <th scope="col">Course Features</th>
            <th scope="col">Complete Mastery +</th>
            <th scope="col" className="font-weight-normal">Complete Mastery</th>
            <th scope="col" className="font-weight-normal">Free</th>
          </tr>
        </thead>
        <tbody>
          <PricingMatrixRow
            strRowTitle="Practice Problem"
            strRowDescr="Number of practice problems included in the program"
            plusElm="1010+"
            bundleElm="1010+"
            packElm="Limited"
          />
          <PricingMatrixRow
            strRowTitle="Diagnostic Quizzes"
            strRowDescr="Number of diagnostic quizzes included in the program"
            plusElm="14"
            bundleElm="14"
            packElm="Limited"
          />
          <PricingMatrixRow
            strRowTitle="Strategy Videos"
            strRowDescr="50+ Test-taking strategies discussed in detailed videos"
            plusElm={<i className="fas fa-check text-pastel-black"/>}
            bundleElm={<i className="fas fa-check text-pastel-black"/>}
            packElm=""
          />
          <PricingMatrixRow
            strRowTitle="Skill Videos"
            strRowDescr="77 Videos teaching every English and Math skill"
            plusElm={<i className="fas fa-check text-pastel-black"/>}
            bundleElm={<i className="fas fa-check text-pastel-black"/>}
            packElm=""
          />
          <PricingMatrixRow
            strRowTitle="Virtual Tutoring"
            strRowDescr="24/7 access to virtual tutoring"
            plusElm={"Highest limits"}
            bundleElm={"Higher limits"}
            packElm="Limited"
          />
          <PricingMatrixRow
            strRowTitle="5 Academy Guarantee"
            strRowDescr={<span>Get a full refund if you are not satisfied. <a href="/terms-of-s">See Terms</a></span>}
            plusElm={<i className="fas fa-check text-pastel-black"/>}
            bundleElm=""
            packElm=""
          />
          <PricingMatrixRow
            strRowTitle="Tutoring"
            strRowDescr="Amount of 1-1 time with 5 Academy tutors included in the program"
            plusElm="10 hours"
            bundleElm=""
            packElm=""
          />
        </tbody>
      </table>
    </div>
  );
};

export {
  PricingMatrixRow,
  PricingMatrix
};