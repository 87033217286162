// Copyright 2021 5 Academy
import React from 'react';
import { BackgroundForSection, StandardTextSection } from '../indexShared';

const GotQuestionsSection = () => {

    return (
      <BackgroundForSection strClassName="bg-white py-4" strId="GotQuestions" numMarginTop={0}>
            <StandardTextSection>
                <div className="w-75">
                    <h4 className="mb-3 font-weight-bold">
                        Still got questions?
                    </h4>
                    <div className='mb-4 w-75'>
                        Call us at 1<p className='d-none'>3-23-342</p>-60<p className='d-none'>4</p>8<p className='d-none'>5</p>-6<p className='d-none'>1</p>2<p className='d-none'>893</p>0-<p className='d-none'>-</p>5<p className='d-none'>8</p>8<p className='d-none'>131</p>1<p className='d-none'>-1</p>2, or schedule a call with Shaheer at a time of your choice. Looking forward to talking to you!
                    </div>
                    <button onClick={() => { Calendly.showPopupWidget('https://calendly.com/5academy/consultation?hide_gdpr_banner=1'); return false; }} style={{ pointerEvents: "auto" }} className="btn btn-lg btn-primary-1 mr-4">Schedule a call with Shaheer</button>
                </div>
            </StandardTextSection>
      </BackgroundForSection>
  );
};

export {
    GotQuestionsSection
}