// Copyright 2021 5 Academy
import React from 'react';
import { BackgroundForSection } from '../indexShared';

const CallToActionSectionOne = ({strCamp=""}) => {
  return (
    <BackgroundForSection strClassName="bg-pastel-background py-4" strId="CallAc1" numMarginTop={0}>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-row">
          <h1 className="display-4 font-weight-bold">{'“'}</h1>
          <h4 className="my-4 font-weight-bold">Students love the prep, and the results ✅</h4>
        </div>
        <a href={(strCamp) ? `/register/${strCamp}` : '/register'} className="my-auto btn btn-lg btn-primary-1">Get started</a>
      </div>
    </BackgroundForSection>
  );
};
  
export default CallToActionSectionOne;