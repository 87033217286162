// Copyright 2021 5 Academy
import React from 'react';

export const FooterContainer = ({children}) => {
  return (
    <footer className="sticky-footer" style={{ 'borderRadius':'0em 1.2em 0em 0em'}}>
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
          <span><a href="/terms-of-s" className="text-muted">Terms of Service</a> | <a href="/privacy-policy" className="text-muted">Privacy Policy</a></span>
          <br/>
          <br/>
          <span>Copyright &copy; 5 Academy 2018-2024</span>
        </div>
        <div className="mt-4 copyright">
          {children}
        </div>
      </div>
    </footer>
  );
};