import React from 'react';
import { CustomParser } from '../../utils/customParser';
import { Style } from '../../utils/style';


const landingPageCardStyle = {
  width: '355px',
  marginBottom: '2rem'
};
  
const FullRowContainerStyle = {
  width: '100vw',
  position: 'relative',
  marginLeft: '-50vw',
  left: '50%'
};
  
const BackgroundForSection = ({strClassName, strId, numMarginTop, children, numZIndex = null, objStyle={}}) => {
  return (
  //@ts-ignore
    <div id={strId} className={strClassName} style={{ ...FullRowContainerStyle, ...Style.marginOffset(numMarginTop), ...Style.zIndex(numZIndex), ...objStyle }}>
      <div className="container-fluid">
        {children}
      </div>
    </div>
  );
};
  
const CstmEllipse = ({strClassName, strStyle}) => {
  return (
    <svg width="36" height="58" viewBox="0 0 36 58" className={strClassName} style={strStyle} xmlns="http://www.w3.org/2000/svg">
      <path d="M36 26.3621C36 40.6187 23.6712 47.0839 15.7808 54.8542C0 65.9172 0 40.6187 0 26.3621C0 12.1056 9.27323 0.54834 20.7123 0.54834C32.1514 0.54834 36 12.1056 36 26.3621Z" fill="currentColor"/>
    </svg>
  );
};
  
const LandingPageCard = ({ strTitle, strEllipseClass, strBody = '', bTestimonial = false, bLast = false }) => {
  return (
    <div className={`card-body shadow bg-white h-100 justify-content-center align-items-center${bLast ? ' mr-2' : ' mr-4'}`} style={landingPageCardStyle}>
      <CstmEllipse strClassName={strEllipseClass} strStyle={{...Style.marginOffset(-60), ...Style.marginOffset(90,'marginLeft','%')}} />
      {(bTestimonial) ? <></> : <img src="/img/la_pge/cstmCheckBrd.svg" style={{ ...Style.marginOffset(-60), ...Style.marginOffset(-55, 'marginLeft') }} />}
      <h5 className={'py-2 font-weight-light'}>{CustomParser.handleTextBold(strTitle)}</h5>
      <h5 className={'py-2 font-weight-bold'}>
        {strBody}
      </h5>
    </div>
  );
};
  // supports bolding only a single segment of text nested with **
const TestimonialsAbridgeCard = ({strTestimonial}) => {
  return (
    <div className="m-2 card-body bg-white shadow" style={{ borderRadius: '40px', width: '250px'}}>
      <h6 className="text-center">{'"'}{CustomParser.handleTextBold(strTestimonial)}{'"'}</h6>
    </div>  
  );
};
  
const PricingCard = ({strPlan, strPricing, strTagline, arrPlans, bHighlight=false}) => {
  return (
    <div className={`col-md-5 col-lg-4 m-2 card-body ${bHighlight ? 'bg-pastel-orange border border-pastel-orange-vibrant' : 'bg-white'}`} style={landingPageCardStyle}>
      {bHighlight ? <img src="/img/la_pge/highlight.svg" alt="feature" style={{...Style.marginOffset(-50),marginLeft:'80%'}} /> : <></>}
      <div className="mb-4">
        <h4 className="font-weight-bold">{strPlan}</h4>
        <h1 className="font-weight-bold my-3">
          <span className="text-3xl font-weight-bold">{strPricing}</span>
        </h1>
        <h4 className="text-gray-800">{strTagline}</h4>
        {
          arrPlans.map((itm, indx) => {
            return (
              <div key={indx} className="d-flex py-2">
                <div className="col-xs-1">
                  <i className="fas fa-check text-success"></i>
                </div>
                <div className="col">
                  <span className="font-weight-bold text-pastel-black">{itm}</span>
                </div>
              </div>
            );
          })
        }
      </div>
      <a className={'btn btn-block btn-pastel-black'} href="/courses">
          Start Now
      </a>
    </div>
  );
};

const StandardVisualTextSection = ({
  strImgSrc,
  strImgAlt,
  strImgWidth,
  strImgClass='img-fluid',
  children,
  reactElmVisual = null,
  bTextLeft = true,
  bShadow = true
}) => {
  return (
    <div className={`d-flex${bTextLeft ? '' : ' flex-row-reverse'} justify-content-between my-4 py-4`}>
      <div className={`my-auto${bTextLeft ? '' : ' text-right'}`}>
        {children}
      </div>
      <div className="my-auto d-none d-md-block">
        {
          null === reactElmVisual ?
            <img src={strImgSrc} alt={strImgAlt} width={strImgWidth} className={`${strImgClass}${bShadow ? ' shadow' : ''}`}/>
            :
            reactElmVisual
        }
      </div>
    </div>
  );
};

const StandardTextSection = ({
  children,
}) => {
  return (
    <div className={'justify-content-between py-4'}>
      <div className={'my-auto'}>
        {children}
      </div>
    </div>
  );
};

export {
  BackgroundForSection,
  CstmEllipse,
  LandingPageCard,
  TestimonialsAbridgeCard,
  PricingCard,
  StandardVisualTextSection,
  StandardTextSection
};