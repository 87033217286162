// Copyright 2021 5 Academy
import React from 'react';
import { CustomParser } from '../../../utils/customParser';
import { BackgroundForSection, StandardVisualTextSection } from '../indexShared';

const FeatureSection = ({
  numStep,
  strImgSrc,
  strImgAlt,
  strImgClass='img-fluid',
  strTitle,
  children,
  reactElmVisual = null,
  strTextColor = 'text-pastel-background',
  strTextClass = 'col-md-8 col-sm-12 font-weight-light',
  strWidth = '1000px',
  bTextLeft = true,
  bShadow = true
}) => {
  return (
    <StandardVisualTextSection
      strImgSrc={strImgSrc}
      strImgAlt={strImgAlt}
      strImgClass={strImgClass}
      strImgWidth={strWidth}
      bTextLeft={bTextLeft}
      reactElmVisual={reactElmVisual}
      bShadow={bShadow}
    >
      <div className={`${strTextClass}${bTextLeft ? '' : ' float-right'}`}>
      <h1 className={`display-1 ${strTextColor} font-weight-bold`}>{numStep}</h1>
      <h4 className="mb-3 font-weight-bold">{CustomParser.handleTextNewLine(strTitle)}</h4>
        {children}
      </div>
    </StandardVisualTextSection>
  );
};
  
const BackgroundForCourses = ({strBackground,strId,children}) => {
  return (
    <BackgroundForSection
      strClassName={`${strBackground} py-4 d-flex justify-content-center align-items-center`}
      strId={strId}
      objStyle={{ height: '693px' }}
      numMarginTop={0}>
      {children}
    </BackgroundForSection>
  );
};

export {
  FeatureSection,
  BackgroundForCourses
}