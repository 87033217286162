// Copyright 2021 5 Academy
import React from 'react';
import { Style } from '../../../utils/style';
import { BackgroundForSection, LandingPageCard, StandardVisualTextSection } from '../indexShared';

const Z_INDEX_IMAGE = 5;
const Z_INDEX_TOP = 4;

const TestimonialSection = () => {
  return (
    <BackgroundForSection strClassName="bg-pastel-background cdsweagfzxcv py-4" strId="Testimonials" numMarginTop={0}>
      <StandardVisualTextSection
        strImgSrc={''}
        strImgAlt={''}
        strImgWidth={0}
        reactElmVisual={
          <iframe style={{pointerEvents:'unset'}} width="568" height="319" src="https://www.youtube.com/embed/6hWAcxAdGGo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        }
      >
        <h5 className="font-weight-bold mb-3">See how Zain improved from a <strong className="text-primary-1">26</strong> to a <strong className="text-primary-1">32</strong></h5>
        <p className={'w-50 font-weight-light'}>
          {'Using 5 Academy\'s tutoring and strategy videos, Zain was able to improve 6 points. See how he did it. '}
        </p>
      </StandardVisualTextSection>
      <StandardVisualTextSection
        strImgSrc={'/img/dg.png'}
        strImgAlt={'picture of student'}
        strImgWidth={'309px'}
        bTextLeft={false}
        bShadow={false}
      >
        <p className={'w-50 font-weight-light float-right'}>
          {'"I had the ACT in 2 weeks and needed 1-1 tutoring ASAP. With 5 Academy\'s '}
          <strong className="font-weight-bold">Complete Mastery+</strong>
          {' program, I was able to improve '}
          <strong className="font-weight-bold text-primary-1">6 points in just 13 days.{'"'}</strong>
          <br />
          <br />
          <em>- Donny</em>
        </p>
      </StandardVisualTextSection>
      <div className="d-flex flex-row-reverse">
        <img src="/img/la_pge/obj/cylinder.svg" alt="cylinder 3d object" style={{ ...Style.marginOffset(-100), ...Style.marginOffset(-110,'marginRight'), ...Style.zIndex(Z_INDEX_IMAGE) }}/>
      </div>
      <h3 className="font-weight-bold text-center" style={Style.marginOffset(-90)}>
        Hear it from even more students in our programs 🚀
      </h3>
      <div className="mt-4 pt-4" style={Style.zIndex(Z_INDEX_TOP)}>
        <div className="row">
          <LandingPageCard
            bTestimonial={true}
            strBody="- Leonardo, Complete Mastery Bundle"
            strEllipseClass="text-pastel-green"
            strTitle="“ I improved from a **23 to a 29** using the content on this site. Thanks!!! “" />
          <LandingPageCard
            bTestimonial={true}
            strBody="- Adi, Complete Mastery+"
            strEllipseClass="text-pastel-pink"
            strTitle="“ I **increased my overall score 10 points** with Shaheer's tutoring! “" />
          <LandingPageCard
            bLast={true}
            bTestimonial={true}
            strBody="- Zeynep, Complete Mastery Bundle"
            strEllipseClass="text-pastel-purple"
            strTitle="“ My reading went from 27 to 32 with watching your passage glancing video and implementing it, and my overall score went from **24 to 30 in 5-6 weeks**. “" />
          <LandingPageCard
            bTestimonial={true}
            strBody="- Ekta, Complete Mastery+"
            strEllipseClass="text-pastel-blue"
            strTitle="“ I just wanted to let you know **I got my goal score of a 30 after starting at a 24** and wanted to say thank you so much for all the help and the resources your website has given!!! “" />
          <LandingPageCard
            bTestimonial={true}
            strBody="- Ella, Complete Mastery+"
            strEllipseClass="text-pastel-green"
            strTitle="“ I improved my **composite score to a 30 within just 5 weeks** after the 10 tutoring sessions with Shaheer. Thanks a lot! “" />
          <LandingPageCard
            bTestimonial={true}
            bLast={true}
            strBody="- Kevin, Complete Mastery+"
            strEllipseClass="text-pastel-pink"
            strTitle="“ I improved my score from a **20 to a 29 in less than 4 weeks** with 5 Academy's tutoring program. “" />
          <LandingPageCard
            bTestimonial={true}
            strBody="- Zikora, Complete Mastery+"
            strEllipseClass="text-pastel-green"
            strTitle="“ My composite score increased a lot with 5 Academy (from a **23 to a 28**). I was able to raise my math score from an 18 to a 27 and my reading score from a 24 to a 33!! “" />
          <LandingPageCard
            bTestimonial={true}
            strBody="- Noah, Complete Mastery+"
            strEllipseClass="text-pastel-pink"
            strTitle="“ I increased my score **+7 points (24 👉🏼 31) within 5-6 weeks** with 5 Academy's program “" />
          <LandingPageCard
            bLast={true}
            bTestimonial={true}
            strBody="- Ian, Complete Mastery+"
            strEllipseClass="text-pastel-purple"
            strTitle="“ I improved **5 points in a few weeks** with 5 Academy! “" />
          <LandingPageCard
            bTestimonial={true}
            strBody="- Viv, Complete Mastery+"
            strEllipseClass="text-pastel-blue"
            strTitle="“ I bought the program yesterday and **improved 9 points** just by reading the English rules on your website and watching a few of your videos! “" />
        </div>
      </div>
    </BackgroundForSection>
  );
};
  
export default TestimonialSection;