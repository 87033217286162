// Copyright 2021 5 Academy
import React from 'react';
import { CustomParser } from '../../../utils/customParser';
import { Style } from '../../../utils/style';

const Z_INDEX_TOP = 4;

const LandingNav = (props) =>
{
  const { name, link } = props;

  return (
    <a href={link} className="m-2">
      <span className="text-pastel-black font-weight-bold">{name}</span>
    </a>
  );    
};

const HeroSection = ({
  strHeader,
  strBody,
  strCamp = "",
  strHighlightText = "College Prep",
  strHighlightLink = "/college-consultation"
}) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <img src="/img/la_pge/logo.svg" alt="5 Academy Logo" width="150px" className="img-fluid my-auto" style={Style.marginOffset(-20)}/>
        </div>
        <div className="d-flex flex-row my-auto">
          <LandingNav name={strHighlightText} link={strHighlightLink} />
          <LandingNav name="Login" link="/login"/>
        </div>
      </div>
      <div className="d-flex justify-content-between" style={{ ...Style.marginOffset(150), ...Style.marginOffset(200, 'marginBottom'), ...Style.zIndex(Z_INDEX_TOP)}}>
        <div className="text-pastel-black">
          <h1 className="display-4 font-weight-bold">{CustomParser.handleMultipleParser(strHeader)}</h1>
          <h5 className="w-75">{CustomParser.handleMultipleParser(strBody)}</h5>
          <div className="d-flex my-4">
            <a href={(strCamp) ? `/register/${strCamp}` : '/register'} className="btn btn-lg btn-primary-1 mr-4">Get Started</a>
          </div>
        </div>
        <div className="d-none d-md-block">
          <img src="/img/la_pge/3779225-1.jpg" alt="infographic 5 academy" width="419px" />
        </div>
      </div>
    </>
  );
};
  
export default HeroSection;