// Copyright 2021 5 Academy
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BasePageWrapper, dID } from '../../common.jsx';
import { FooterContainer } from '../footer.jsx';
import CallToActionSectionOne from './CallToAction/CallToActionOne';
import CallToActionSectionTwo from './CallToAction/CallToActionTwo';
import CourseFeatures from './CourseFeatures/CourseFeature';
import FAQSection from './FAQ/FAQ';
import { GotQuestionsSection } from './GotQuestions/GotQuestions';
import GuarenteeSection from './GuarenteeSection/GuarenteeSection';
import HeroSection from './HeroSection/HeroSection';
import ProgramSection from './ProgramsSection/ProgramSection';
// import { PromotionSection } from './Promotion/Promotion.jsx';
import SmallTestimonial from './TestimonialSection/SmallTestimonial/SmallTestimonial';
import TestimonialSection from './TestimonialSection/TestimonialSection';
import { WhoAreWeSection } from './WhoAreWe/Whoarewe';

const identf_root = dID('indxLPage');

const Index = () =>
{
  return (
    <>
      <div id="wrapper">
        <BasePageWrapper>
          <div className="container-fluid text-pastel-black">
            <HeroSection
              strHeader="Improve your ACT score 5-9 points* within **weeks**"
              strBody={'Improve faster with custom prep recommendations and easy-to-grasp lessons.\n\nStarts at just **$19**.'}
              strHighlightLink=''
              strHighlightText=''
            />
            <SmallTestimonial />
            <CourseFeatures />
            <CallToActionSectionOne/>
            <TestimonialSection />
            <CallToActionSectionTwo/>
            <ProgramSection />
            <GuarenteeSection strBackground="bg-white" strHeader="5 Academy Guarantee">
              {'We believe in our students, and we believe in our prep. If you aren\'t satisfied with your score, we\'ll provide you with a 100% refund on select programs. '}
              <a href="/terms-of-s">See terms!</a>
            </GuarenteeSection>
            <FAQSection />
            <WhoAreWeSection />
            <GotQuestionsSection/>
          </div>    
        </BasePageWrapper>
      </div>
      <span id="error-message"></span>
      <FooterContainer>
        <span>
          {'*The 5-9 point claim is based on self-reported scores from students enrolled in plans with 10 or more hours of 1-1 tutoring.'}
        </span>
        <br />
        <br />
        <span>
          {'**You will get a 15-Minute tutoring session while you are subscribed. Keep in mind that unused tutoring sessions do not carry over from month to month and will expire when your plan expires.'}
        </span>
        <br />
        <br />
        <span>
          {'^Some of the features in our programs are in Beta release, and users may observe minor bugs.'}
        </span>
        <br />
        <br />
        <span>
          {'ACT is a register trademark of ACT Incorporated™. ACT Incorporated™ is not affilated with this site or its owners and does not endorse it'}
        </span>
      </FooterContainer>
    </>
  );
};

if(identf_root)
{
  createRoot(identf_root).render(<Index />);
}

