// Copyright 2021 5 Academy
import React from 'react';
import { Style } from '../../../utils/style';
import { BackgroundForSection, PricingCard } from '../indexShared';
import { PricingMatrix } from './PricingMatrix/PricingMatrix';
  
const Z_INDEX_TOP = 4;

const ProgramSection = () => {
  const PLAN_OPTIONS_COMPLETE = ['Unthrottled access to all our content', 'Access to all our videos', 'Higher limits to virtual tutoring' ];
  return (
    <BackgroundForSection strClassName="bg-pastel-blue mCF_Illustration py-4" strId="OurPrograms" numMarginTop={0}>
      <div style={{...Style.marginOffset(7,'marginTop','%'),...Style.marginOffset(7,'marginBottom','%')}}>
        <h1 className="display-4">
          <span className="font-weight-bold">Our</span>
          <span className="font-weight-lighter"> Courses</span>
        </h1>
        <h5 className="font-weight-light">
                Choose a prep program^ below so you can start leveling up your ACT Score.
                If you are not sure which program is right for you just send us message via the
                website chat and we’ll get you sorted.
        </h5>
      </div>
      <div className="mt-4 pt-4" style={Style.zIndex(Z_INDEX_TOP)}>
        <div className="row">
          <PricingCard
            bHighlight={true}
            strPlan="Complete Mastery +"
            strPricing="$695"
            strTagline="For those looking to make significant improvement"
            arrPlans={[...PLAN_OPTIONS_COMPLETE, '10 hours of 1-1 time with 5 Academy tutors']}
          />
          <PricingCard
            strPlan="Complete Mastery"
            strPricing="$19/month"
            strTagline="For those who want to take advantage of our online content"
            arrPlans={[...PLAN_OPTIONS_COMPLETE]}
          />
          <PricingCard
            strPlan="Free"
            strPricing="$0"
            strTagline="For those who want to take advantage of our free content"
            arrPlans={['Preview of our content']}
          />
        </div>
      </div>
      <PricingMatrix/>
    </BackgroundForSection>
  );
};
  
export default ProgramSection;