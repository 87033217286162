// Copyright 2021 5 Academy
import React from 'react';
import { BackgroundForSection, TestimonialsAbridgeCard } from '../../indexShared';

const SmallTestimonial = () => {
  return (
    <>
      <BackgroundForSection strClassName="bg-pastel-background py-4" strId="Test_abridge" numMarginTop={0}>
        <div className="row">
          <TestimonialsAbridgeCard strTestimonial="I improved **7 points** in 5 weeks with your program!"/>
          <TestimonialsAbridgeCard strTestimonial="My score shot up **6 points** with your tutoring. Thanks!"/>
          <TestimonialsAbridgeCard strTestimonial="I started at a **26 and jumped to a 32** with your help!"/>
          <TestimonialsAbridgeCard strTestimonial="I improved **9** points overall within a few weeks!"/>
        </div>
      </BackgroundForSection>
    </>
  );
};
  
export default SmallTestimonial;