// Copyright 2021 5 Academy
import React from 'react';
import { BackgroundForSection } from '../indexShared';

const CallToActionSectionTwo = ({strCamp=""}) => {
  return (
    <BackgroundForSection strClassName="bg-primary-3 py-4 text-white" strId="CallAc2" numMarginTop={0}>
      <div className="d-flex justify-content-between">
        <h5 className="my-4 font-weight-bold w-75">Ready to get results just like these students?<br/>Our easy-to-use, customizable programs are waiting for you!</h5>
        <a href={(strCamp) ? `/register/${strCamp}` : '/register'} className="my-auto btn btn-lg btn-primary-1">Get started</a>
      </div>
    </BackgroundForSection>
  );
};
  
export default CallToActionSectionTwo;