// Copyright 2021 5 Academy
import React from 'react';
import { CustomParser } from '../../../utils/customParser';
import { Style } from '../../../utils/style';
import { BackgroundForSection } from '../indexShared';

const FAQElem = ({strQuestion, strAnswer}) => {
  return (
    <div className="col-6 my-4">
      <h5 className="font-weight-bold">{strQuestion}</h5>
      <p>
        {CustomParser.handleTextNewLine(strAnswer)}
      </p>
    </div>
  );
};
  
const FAQSection = () => {
  return (
    <BackgroundForSection strClassName="py-4" strId="FAQSection" numMarginTop={0} objStyle={{ ...Style.marginOffset(7, 'marginTop', '%'), ...Style.marginOffset(7, 'marginBottom', '%') }}>
      <h1 className="display-4">
        <span className="font-weight-bold">Frequently</span>
        <span className="font-weight-lighter"> asked questions</span>
      </h1>
      <div className="row mt-4 pt-4">
        <FAQElem
          strQuestion="How do I improve my ACT Score?"
          strAnswer={'Here\'s what we\'ve seen work for our students. First, you need to build a study plan based on how much time you have and how much you want to improve. We\'ll do all of this with you. Second, you\'ve got to master the skills tested on this exam. From comma rules, to quadratic formula, we\'ve got you covered with our problem sets. Our tutoring, practice tests, and skill study guide only make this easier.'}
        />
        <FAQElem
          strQuestion="Did you actually improve 12 points?"
          strAnswer={'Yes. I started with a score of 21 (E 24 M 25 R 16 S 19) on the first complete ACT exam that I took. After implementing all of the strategies in our courses, I improved to a 33. My super score is a 34. Both of these are 99th percentile 😊'}
        />
        <FAQElem
          strQuestion="What is tutoring like?"
          strAnswer={`There's a plethora of things we do in tutoring sessions that students find helpful. First and foremost, we build you a custom study plan based on how much time you have and how much you want to improve. This relates to which sections you need to improve, what you need to study, how much you need to study it, when you study it, and with what techniques.
            After that, we'll get into filling your skill gaps by discussing problems you're struggling with, teaching you skills, demo'ing strategies in front of you via screen-share, and creating new problems for you on the spot and solving them. We'll also keep up with and adapt your study plan as needed.`}
        />
        <FAQElem
          strQuestion="Can you build me a study plan?"
          strAnswer={'Yes. We\'ll build you a custom study plan based on how much time you have and how much you want to improve. This relates to which sections you need to improve, what you need to study, how much you need to study it, when you study it, and with what techniques.'}
        />
        <FAQElem
          strQuestion="Can I cancel my subscription at anytime?"
          strAnswer={'Yes. You can cancel your subscription at anytime. To prevent a charge for the following month, you will need to cancel atleast one day before your plan\'s monthly anniversary. A quick note, aside from Complete Mastery + all our plans are monthly subscription.'}
        />
        <FAQElem
          strQuestion="Do you offer refunds?"
          strAnswer={'We don\'t typically offer refunds for our monthly subscriptions. However, if you experience issues during your prep with us, then we will do our best to accommodate you.\nIf you purchase Our Complete Mastery+, you may be eligible for a refund. Click our terms of service button below for more details.'}
        />
        <FAQElem
          strQuestion="How will I be able to reach out to you when I have questions?"
          strAnswer={'You can reach us by clicking on our photo in the bottom right hand corner, or by scheduling a tutoring session from the dashboard.'}
        />
      </div>
    </BackgroundForSection>
  );
};

export default FAQSection;