// Copyright 2021 5 Academy
import React from 'react';
import { BackgroundForSection } from '../indexShared';

const GuarenteeSection = ({
  strBackground,
  strHeader,
  children,
  bReverse = false,
  bRohit = true,
  strClass=""
}) => {
  return (
    <BackgroundForSection
      strClassName={strBackground}
      numMarginTop={0}>
      <div className={`${bReverse ? 'flex-row-reverse ' : ''}row my-4 py-4`}>
      <div className="col-md-5 d-flex flex-row mb-4">
        <div>
            <img src={`/img/${bRohit ? 'ro' : 'sh'}.png`} alt="rohit" width="135px" className="img-fluid"/>
        </div>
        <div className="ml-3 my-auto d-flex h-50 justify-content-center align-items-center card card-body bg-white text-pastel-black shadow">
          <h4 className="mb-1">{strHeader}</h4>
        </div>
      </div>
      <div className="col my-auto float-right">
        <h6 className={strClass}>
          {children}
        </h6>
      </div>
      </div>
      </BackgroundForSection>
  );
};
  
export default GuarenteeSection;