// Copyright 2021 5 Academy
//@ts-nocheck
import React, { Fragment } from 'react';
import katex from 'katex';

import { IF } from '../common';

export const CustomParser = {
  renderEnglishPassage: (text, questionStart=0) => {
    /**
     * Expect Text that is like the following:
     * Here is some ||underlined text||[1] that corresponds to a question.
     * 
     * Here is *italicized text* in the passage.
     * 
     * This is another example with a number [2] that is not associated with underlined text.
     */
    // Regex for finding the underlined text with [Number]
    const underlineRegex = /\[(\d+)\]\|\|([^|]+)\|\|/g;
    // Regex for finding italicized text
    const italicRegex = /\*([^*]+)\*/g;
    // Regex for finding bold text
    const boldRegex = /\*\*([^*]+)\*\*/g;
    // Regex for finding numbers in square brackets not associated with underlined text
    const numberRegex = /\[(\d+)\]/g;
  
    // Split text into parts based on the underlined regex
    let parts = text.split(underlineRegex);
    let finalParts = [];
  
    for (let i = 0; i < parts.length; i++) {
      if (i % 3 === 1) {
        const number = questionStart + parseInt(parts[i]);
        // Underlined text part
        finalParts.push(
          <div key={i} style={{ display: 'inline-block' }}>
            {/** Number */}
            <div id={`num-${number}`} style={{
              fontSize: 'smaller',
              border: '1px solid black',
              padding: '4px',
              width: '25px',
              margin: 'auto',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>{number}</div>
            {/** Text */}
            <span style={{ textDecoration: 'underline' }}>{parts[i + 1]}</span>
          </div>
        );
        i++; // Skip the text part following number
      } else {
        // Regular text part, need to process for italic text and numbers not associated with underlined text
        let subParts = parts[i].split(numberRegex);
        for (let j = 0; j < subParts.length; j++) {
          if (j % 2 === 1) {
            const number = questionStart + parseInt(subParts[j]);
            // Number in square brackets part
            finalParts.push(<span key={`${i}-${j}`} id={`num-${number}`} className='d-none' style={{ border: '1px solid black', padding: '3px' }}>{number}</span>);
          } else {
            // Regular text part, need to process for italic text
            let innerParts = subParts[j].split(boldRegex);
            for (let k = 0; k < innerParts.length; k++) {
              if (k % 2 === 1) {
                // Bold text part
                finalParts.push(<strong key={`${i}-${j}-${k}`}>{innerParts[k]}</strong>);
              } else {
                // Regular text part, need to process for bold text
                let italicParts = innerParts[k].split(italicRegex);
                for (let l = 0; l < italicParts.length; l++) {
                  if (l % 2 === 1) {
                    // Italicized text part
                    finalParts.push(<em key={`${i}-${j}-${k}-${l}`}>{italicParts[l]}</em>);
                  } else {
                    // Regular text part
                    finalParts.push(italicParts[l]);
                  }
                }
              }
            }
          }
        }
      }
    }
    return <>{finalParts}</>;
  },
  handleTextBold: (strText,boldColor) => {
    const arrBuf = strText.split('**');
    const elements = [];
  
    for (let i = 0; i < arrBuf.length; i++) {
      if (i % 2 === 0) {
        elements.push(arrBuf[i]); // Add non-bold text
      } else {
        elements.push(
          <strong key={i} className={`font-weight-bold ${boldColor}`}>
            {arrBuf[i].trim()}{/* Add bold text */}
          </strong>
        );
      }
    }
  
    return <>{elements}</>;
  },
  handleTextNewLine: (strText) => {
    const arrBuf = strText.split("\n");
    return (
      <>
        {arrBuf.map((value, index) => {
          return (
            <Fragment key={index}>
              {value}
              <IF cond={arrBuf.length - 1 !== index}>
                <br />
              </IF>
            </Fragment>
          )
        })}
      </>
    );
  },
  handleTextNewLineAndBold: (strText,boldColor) => {
    const arrBuf = strText.split('**');
    const elements = [];
  
    for (let i = 0; i < arrBuf.length; i++) {
      if (i % 2 === 0) {
        elements.push(CustomParser.handleTextNewLine(arrBuf[i])); // Add non-bold text with new lines
      } else {
        elements.push(
          <strong key={i} className={`font-weight-bold ${boldColor}`}>
            {CustomParser.handleTextNewLine(arrBuf[i])} {/* Add bold text with new lines */}
          </strong>
        );
      }
    }
  
    return <>{elements}</>;
  },
  handleMultipleParser: (strText, boldColor='text-primary') => {
    let rtnElm = <></>;
    if (strText)
    {
      if (strText.includes('**') && strText.includes('\n'))
        rtnElm = CustomParser.handleTextNewLineAndBold(strText,boldColor);
      else if (strText.includes('**') && !strText.includes('\n'))
        rtnElm = CustomParser.handleTextBold(strText,boldColor);
      else if (!strText.includes('**') && strText.includes('\n'))
        rtnElm = CustomParser.handleTextNewLine(strText);
    }

    return rtnElm;
  },
  splitText(text, startDelimiters, endDelimiters, fn) {
    // Call the secondary function to split the text into parts based on the delimiters
    const parts = CustomParser.splitTextByDelimiters(text, startDelimiters, endDelimiters, fn);
    // Return the array of parts joined back into a string
    return parts.join('');
  },
  // Secondary function to split the text into parts based on the delimiters
  splitTextByDelimiters(text, startDelimiters, endDelimiters, fn) {
    let parts = [];
    let startIndex = 0;
    
    // Iterate over each start delimiter
    for (let i = 0; i < startDelimiters.length; i++) {
      // Find the position of the next occurrence of the start delimiter
      let nextStartIndex = text.indexOf(startDelimiters[i], startIndex);
      
      // Continue iterating until all occurrences of the start delimiter are processed
      while (nextStartIndex !== -1) {
        // Find the position of the corresponding end delimiter
        const endIndex = CustomParser.findEndDelimiter(text, nextStartIndex + startDelimiters[i].length, endDelimiters);
        
        // If the end delimiter is found, extract the part of the text between the start and end delimiters
        if (endIndex !== -1) {
          const precedingText = text.substring(startIndex, nextStartIndex);
          const part = text.substring(nextStartIndex + startDelimiters[i].length, endIndex);
          const result = fn(part); // Apply the function to the split value
          parts.push(precedingText, result);
          startIndex = endIndex + endDelimiters[i].length;
        }
        
        // Find the position of the next occurrence of the start delimiter
        nextStartIndex = text.indexOf(startDelimiters[i], startIndex);
      }
    }
    
    // Add the remaining text after the last end delimiter
    const remainingText = text.substring(startIndex);
    if (remainingText) {
      parts.push(remainingText);
    }
    
    return parts;
  },
  // Helper function to find the position of the end delimiter
  findEndDelimiter(text, startIndex, endDelimiters) {
    // Iterate over each end delimiter
    for (let i = 0; i < endDelimiters.length; i++) {
      // Find the position of the next occurrence of the end delimiter
      const endIndex = text.indexOf(endDelimiters[i], startIndex);
      
      // If the end delimiter is found, return its position
      if (endIndex !== -1) {
        return endIndex;
      }
    }
    
    // If none of the end delimiters are found, return -1
    return -1;
  },
  renderKatex(expression, isMath=false) {
    let initial = ["$$", "\\(", "\\["], ending = ["$$", "\\)", "\\]"];
    if (expression) {
      if (expression.includes("\\\\\\\\\\\\")) {
        expression = expression.split("\\\\\\\\\\\\")[0];
      }
      if (expression.includes("\n")) {
        return `<pre>${CustomParser.splitText(expression, initial, ending, (katexString) => katex.renderToString(katexString))}</pre>`;
      }
    }
    return CustomParser.splitText(expression, initial, ending, (katexString)=>katex.renderToString(katexString));
  },
  renderKatexAndText(expression, isMath=false) {
    let initial = ["$$", "\\(", "\\["];
    let containsKatex = expression && expression !== '' && initial.some(delimiter => expression.includes(delimiter));

    if (!containsKatex) {
      // No KaTeX delimiters found, return the original expression
      return expression;
    }
    else {
      return this.renderKatex(expression, isMath);
    }
  }
};