// Copyright 2021 5 Academy
import React from 'react';
import { BackgroundForSection } from '../indexShared';
import { BackgroundForCourses, FeatureSection } from './CourseShared';
  
const CourseFeatures = () => {
  return (
    <BackgroundForSection strClassName="bg-white pt-4" strId="CourseFeatures" numMarginTop={0}>
      <div className="d-flex justify-content-between my-4 py-4">
        <div className="d-flex flex-row">
          <div>
            <img src="/img/sh.png" alt="shaheer" width="135px" className="img-fluid"/>
          </div>
          <div className="ml-3 my-auto d-flex h-50 justify-content-center align-items-center card card-body bg-white shadow">
            <h4 className="mb-1">How do we prep?</h4>
          </div>
        </div>
        <div className="d-none d-md-block my-auto">
          <h6>{'It\'s a simple process.'}<br/>We pack it into a few steps</h6>
        </div>
      </div>
      <BackgroundForCourses strBackground="bg-white" strId="PSetsCourse">
        <FeatureSection
          numStep={1}
          strTitle={'77 Skills\n1010+ Skill-sorted problems 📝'}
          strImgSrc="/img/course.gif"
          strImgAlt="gif of problem set"
          strTextColor="text-blue-25">
          Our problem bank has 1010+ problems sorted into each of the 77 skills tested on the exam, allowing you to master each one, one at a time
        </FeatureSection>
      </BackgroundForCourses>
        
      <BackgroundForCourses strBackground="bg-blue-25" strId="VideosSkillsCourse">
        <FeatureSection
          numStep={2}
          strTitle={'Videos for every single skill 📺'}
          strImgSrc="/img/videos.png"
          strImgAlt="image of three videos in the course the images"
          bTextLeft={false}
          bShadow={false}
          strTextColor="text-white"
        >
          {`Learning the ACT's tested skills is essential. That's why our course includes 75+ in-depth, easy-to-understand, instructional videos,
          one for each of the 25 English and 52 Math skills.`}
        </FeatureSection>
      </BackgroundForCourses>
  
      <BackgroundForCourses strBackground="bg-white" strId="StratVidCourse">
        <FeatureSection
          numStep={3}
          strTitle={'50+ Robust strategy-demo videos ✍🏼'}
          strImgSrc="/img/vidoLib.gif"
          strImgAlt="gif of video libary"
          strTextColor="text-blue-25"
        >
          {`There are dozens of strategies to choose from. Our in-depth course videos help you find the best strategies you for while
          demo'ing the strategies on real problems and passages.`}
        </FeatureSection>
      </BackgroundForCourses>
  
      <BackgroundForCourses strBackground="bg-blue-50" strId="11TutorCourse">
        <FeatureSection
          numStep={4}
          strTitle={'1-on-1 Tutoring with 5 Academy Tutors ⭐️'}
          strImgSrc="/img/tutors.png"
          strImgAlt="image of Tutors"
          bTextLeft={false}
          bShadow={false}
          strTextColor="text-white"
        >
          <span>
            {'There\'s nothing like a touch of personalization, which is exactly what our tutoring provides.'}
          </span>
          <br/><br/>
          <span>
            {`We develop 100% unique, customized study plans for each and every student in our tutoring programs, 
          so you'll always know exactly what to study, how to study, when to study, and with what techniques. `}
          </span>
          <br/><br/>
          <span>
            {'We also go over practice exams, skills, and strategy demo\'s, all via online web conferencing.'}
          </span>
        </FeatureSection>
      </BackgroundForCourses>
    </BackgroundForSection>
  );
};

export default CourseFeatures;