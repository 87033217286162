// Copyright 2021 5 Academy
import React from 'react';
import { createRoot } from 'react-dom/client';


/**
 * This function creates skill elems on clientside
 * @param {*} skils - list of skills
 * @param {*} ind - current ind
 * @param {*} sec - spSecCode
 * @param {*} secFull - prtId
 * @param {*} spSec - prtId
 */
export function crtSkills(skills,ind,sec,secFull,spSec,idTbl=null,bPremium=false)
{
  const sklName = (undefined === skills.name)?skills:skills.name;
  let trNewElm = createRoot(dCrt('tr'));
  trNewElm.render(
    <>
      <td>
        <p className="mb-2">{sklName}</p>
        {
          (NotNullOrUndefined(skills.avgWeight)) ?
            <span className="text-primary">{`Typical Skill Weight: ${(skills.avgWeight*100).toFixed(2)}%`}</span>
            :
            <></>
        }
      </td>
      {(bPremium) ?
        <td><a id={sec+''+ind} href={linkHlp(sklName,secFull,'skillDashboard')} className="btn btn-primary text-white">Skill Hub</a></td>
        :
        <td><a id={sec+''+ind} href={genPractLnk(spSec,sklName,secFull)} className="btn btn-primary text-white">Practice</a></td>
      }
      
    </>
  );
  dID((null === idTbl)?sec+'SklsPrt':idTbl).appendChild(trNewElm);
}

export function encodingUrlEncoding(str)
{
  const reS = / /g, reCom = /,/g, ampComp = /&/g, plusComp = /\+/g;
  return str.replace(reS, '%20').replace(ampComp, '%26').replace(reCom, '%2C').replace(plusComp, '%2B');
}

export function decodingUrlEncoding(str)
{
  const reS = /%20/g, reCom = /%2C/g, ampComp = /%26/g, plusComp = /%2B/g;
  return str.replace(reS, ' ').replace(ampComp, '&').replace(reCom, ',').replace(plusComp, '+');
}

/**
 * Formats links for skill videos
 * @param {*} ln 
 * @param {*} ss 
 * @param {*} strInit 
 */
export function linkHlp(ln,ss,strInit='skillvideos',day=-1,bOnlySkill=false)
{
  const str = encodingUrlEncoding(ln);
  return (!bOnlySkill) ? '/'+strInit+'/'+(ss)+'/'+str+(('skillDashboard' === strInit) ? `/${day}` : '') : str;
}

export function genPractLnk(spSec,sklName,secFull,day=-1)
{
  const reDsh = /-/g, reS = / /g, reCom = /,/g, ampComp = /&/g, plusComp = /\+/g;
  const str = sklName.replace(reDsh, 'HGHG').replace(reS, '-').replace(ampComp, '%26').replace(reCom, '%2C').replace(plusComp, '%2B');
  return '/practice/'+spSec+'/'+str+'/'+secFull+'/'+day;
}

/**
 * Find the smaller of two numbers
 * @param {*} i 
 * @param {*} j 
 */
export function sm(i,j){
  if(0===i) return j;
  if(0===j) return i;
  return (i>j)? j:i;
}

/**
 * Find the larger of two numbers
 * @param {*} i 
 * @param {*} j 
 */
export function mx(i,j){
  return (i>j)? i:j;
}

/**
 * Shorthand to create an element
 * @param {*} tg 
 */
export function dCrt(tg) {
  return document.createElement(tg);
}

/**
 * Handle score progress
 * @param {*} scr 
 */
function scrs(scr){
  let scid = 'scr';
  let sections = ['English', 'Math', 'Reading', 'Science'];
  let tmp =0;
  if(scr){
    for(let i = 0; i < 4; i++){
      if(window.innerWidth<=414){
        dID(scid+i).className = 'h4';
        dID(scid+i+'Hd').className = 'h6';
      }
      if(scr.length!==0){
        if(scr.length > 1){
          tmp=trvs(scr,sections[i]);
          if(NotNullOrUndefined(tmp[2]))
          {
            dID(scid+i+'bt').innerText = tmp[0];
            dID(scid+i+'b').innerText = tmp[1];
            dID(scid+i).innerText = tmp[2];
          }
          else dID(scid+i).innerText = '-';
        }
        else
        {
          dID(scid+i).innerText = NotNullOrUndefined(scr[scr.length-1][sections[i]]) ? scr[scr.length-1][sections[i]] : '-';
        }
      }
      else
      {
        dID(scid+i).innerText = '-';
      }
    }
  }
}
export function trvs (ar,sec){
  let m = 0;
  let mn = 0;
  let av = 0;
  let numLen = 0;
  ar.forEach(itm=>{
    if(NotNullOrUndefined(itm[sec]))
    {
      m=mx(m,itm[sec]);
      mn=sm(mn,itm[sec]);
      av += itm[sec];
      numLen += 1;
    }
  });
  av = (av/numLen).toFixed(0);
  return [m,mn,(isNaN(av)) ? null : av];
}

/**
 * Shorthand to find element
 * @param {*} id 
 */
export function dID(id) 
{
  return document.getElementById(id);
}

function HandleRedir(obj)
{
  if(NotNullOrUndefined(obj.redirect))
  {
    window.location.href = obj.redirect;
  }
}

export async function FetchWrapper(url, reqData, method = 'POST') {
  const fetchObj = { 
    method: method, 
    headers: { 'Content-Type': 'application/json' }
  };
  
  // Only add body for non-GET requests
  if (method !== 'GET') {
    fetchObj.body = JSON.stringify(reqData);
  }
  
  return await fetch(url, fetchObj);
}

export async function PostReq(url, reqData, method='POST')
{
  try {
    const response = await FetchWrapper(url, reqData, method);
    const data = await response.json();
    HandleRedir(data);
    return data;
  } catch (ex) {
    console.error(ex);
    return {};
  }
}

export async function GetReq(url)
{
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin'
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    return await response.json();
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}

export function parseMinToString(numMin)
{
  let hrs = Math.round(numMin/60);
  let mins = numMin - (hrs*60);

  return `${hrs}hr ${Math.round(mins)}min`;
}

/**
 * This configures user profile
 * @param {string} nm - nickname 
 * @param {object} bPremium - Complete Bundle Member
 * @param {object} bNPurchased - purchased a plan
 */
export function confiCrChat(nm,bPremium=null, bNPurchased=null){
  // eslint-disable-next-line no-undef
  $crisp.push(['safe', true]);
  // eslint-disable-next-line no-undef
  $crisp.push(['set', 'user:nickname', nm]);
  if(null !== bPremium)
  {
    let strDefault = '';
    if(NotNullOrUndefined(bNPurchased) && bNPurchased) strDefault = 'Free';
    // eslint-disable-next-line no-undef
    $crisp.push(['set', 'session:data', [[['role', (bPremium) ? 'Complete Bundle Access' : strDefault]]]]);
  }
}

/**
 * This function checks if the value is null or undefined
 * @param {*} val 
 * @param {*} dflt 
 */
export function NotNullOrUndefined(val,dflt=null)
{
  let check = (dflt !== undefined && dflt !== null);
  return val !== undefined && val !== null ? (check) ? val : true : (check) ? dflt : false;
}

/**
 * this function validates a array variable is defined and has element(s)
 * @param {*} val 
 * @returns 
 */
export function ValidArray(val)
{
  return (NotNullOrUndefined(val) && Array.isArray(val) && val.length > 0);
}


export async function GetScoresPromise()
{
  return await PostReq('/view-currScores', {});
}

export function GetScores()
{
  GetScoresPromise()
    .then(data => {
      if (NotNullOrUndefined(data?.data?.actScores))
      {
        scrs(data.data.actScores);
      }
    });
}

/**
   * Used to seach for elms on Dashboard and Modals.
   * Hides/Shows elements with className "searchContent"
   * that match the search entry.
   * @param {*} id ID of search bar
   */
export function searchDOM(id)
{
  let inpt = dID(id);
  inpt = inpt?.value.toLowerCase();
  let searchItms = document.getElementsByClassName('searchContent');
  for (let i = 0; i < searchItms.length; i++)
  {
    let itm = searchItms[i];
    let txtValue = itm.textContent || itm.innerText;
    if (txtValue.toLowerCase().indexOf(inpt) > -1) {
      searchItms[i].classList.remove('d-none');
    } else {
      searchItms[i].classList.add('d-none');
    }
  }
}
 
export const Search = ({id='inpt'}) => {
  return(
    <input className="form-control form-control-user" type="text" id={id} onKeyUp={()=>{searchDOM(id);}} placeholder="Search"/>
  );
};

export const SearchParts = ({ objParts, arrObjectFields, id = 'inptPrts', strParentField=null }) => {
  return (
    <input className="form-control form-control-user"
      type="text"
      id={id}
      onKeyUp={() => {
        const txt = dID(id)?.value;
        if (txt === '') {
          objParts.resetSearch();
        }
        else {
          objParts.handleSearch(txt, arrObjectFields, strParentField);
        }
      }}
      placeholder="Search" />
  );
};

/**
 * A card to show progress
 * @param {*} strColor - use primary-1 (default), primary-2, or primary-3 
 * @returns 
 */
export const ProgressCard = ({strTitle='', numTasks, numPercentage=0, strColor='primary-1'}) =>
{
  return(
    <div className={`card p-4 bg-${strColor}`}>
      <div className="row">
        <div className="col-md-6">
          <img src="/img/CardVector.svg" />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <h3 className="font-weight-bold text-light">{strTitle.split(' ')[0]}</h3>
        </div>
        <div className="col-md-12">
          <h3 className="font-weight-bold text-light">{strTitle.split(' ')[1]}</h3>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12 text-white">
          {numTasks} Tasks &nbsp;|&nbsp; {numPercentage}%
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="progress bg-secondary" style={{height: '5px'}}>
            <div className="progress-bar bg-light" style={{width:numPercentage+'%'}} role="progressbar" aria-valuenow={numPercentage} aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
            
    </div>
  );
};

export const DiagnosticCard = () => 
{
  const [objScores, setObjScores] = React.useState({});
  const [numPercentage, setNumPercentage] = React.useState(0);

  React.useLayoutEffect(()=>{
    QueryServ();
  },[]);

  React.useEffect(()=>{
    updatePercentage();
  },[objScores]);

  const updatePercentage = ()=>
  {
    let english = 100;
    let math = 100;
    let science = 100;
    let reading = 100;
    if(objScores.englDiagn < 3){
      english = (objScores.englDiagn/3) * 100;
    }
    if(objScores.mathDiagn < 5){
      math = (objScores.mathDiagn/5) * 100;
    }
    if(objScores.scieDiagn < 3){
      science= (objScores.scieDiagn/3) * 100;
    }
    if(objScores.readDiagn < 3){
      reading = (objScores.readDiagn/3) * 100;
    }
    let percentage = (english+math+reading+science)/4;
    setNumPercentage(percentage);
  };

  const QueryServ = ()=>
  {
    PostReq('/diagQuizScores').then((data)=>{
      setObjScores(data.data);
    });
  };

  function handleSectionGet(sec, currDiagnNum)
  {
    if('Math' === sec) return {org: currDiagnNum, new: currDiagnNum % 5};
    return {org: currDiagnNum, new: currDiagnNum % 3};
  }

  const genButtons = (strSec) =>
  {
    let arrRet = [];
    let numComplete = 0;
    let numActive = 0;
    let numShow = 3;
    let bAppendComplete = false;

    if (strSec === 'Math')
    {
      numShow = 5;
    }
    switch(strSec)
    {
    case 'English':
      numComplete = objScores.englDiagn;
      break;
    case 'Math':
      numComplete = objScores.mathDiagn;
      break;
    case 'Reading':
      numComplete = objScores.readDiagn;
      break;
    case 'Science':
      numComplete = objScores.scieDiagn;
      break;
    }
    numActive = handleSectionGet(strSec, numComplete);
        
    if(numActive.org > numActive.new) 
    {
      bAppendComplete = true;
    }

    for (let i=0; i < numShow; i++)
    {
      arrRet.push(
        <a key={strSec + i} 
          className={
            (i == numActive.new)
              ? 'btn btn-light mr-3 mt-1'
              : 'btn btn-light disabled mr-3 mt-1'
          } 
          href={
            (i==0)
              ? `/diagnQuesInit/${strSec}/-1`
              :`/diagQuestion/${strSec}/-1`
          }>
          {i+1}
        </a>
      );
    }
    return(
      <div className="row p-3">
        <div className="col-md-5 mt-1">
          <h3 className="text-light">{(bAppendComplete) ? `${strSec} (Completed)` : strSec} </h3>
        </div>
        <div className="col">
          {arrRet}
        </div>
      </div>
    );
  };

  return(
    <div className="card bg-primary-3">
      <div className="row">
        <div className="col-lg-4 col-sm-12">
          <div className="card border-0 bg-primary-1 h-100 p-4">
            <div className="row">
              <div className="col-md-6">
                <img src="/img/CardVector.svg" />
              </div>
            </div>
            <div className="row mt-4 mb-5">
              <div className="col-md-12">
                <h3 className="font-weight-bold text-light">Diagnostic</h3>
              </div>
              <div className="col-md-12">
                <h3 className="font-weight-bold text-light">Quiz</h3>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="progress" style={{height: '10px'}}>
                  <div className={'progress-bar bg-white'} style={{width:numPercentage+'%'}} role="progressbar" aria-valuenow={numPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </div> 
        </div>
        <div className="col-lg-8 col">
          <div className="p-2">
            {genButtons('English')}
            {genButtons('Math')}
            {genButtons('Reading')}
            {genButtons('Science')}
          </div>
        </div>
      </div>         
    </div>
  );
};

/**
 * A card that can be toggled to complete a task
 * @param {*}
 * @returns 
 */
export const TaskCard = ({strTitle, strSubTitle, strColor, bCompleted, funcCompleteTask}) => 
{
  return(
    <ListCard bTask={true} strTitle={strTitle} strSubTitle={strSubTitle} strColor={strColor} funcCompleteTask={funcCompleteTask} bCompleted={bCompleted}/>
  );
};

/**
 * Card that contains a link to an activity
 * @param {*} param0 
 * @returns 
 */
export const LinkCard = ({bTop, strColor, strTitle, strSubTitle, strLink, children}) =>
{
  return(
    <ListCard
      bTask={false}
      bTop={bTop}
      strTitle={strTitle}
      strSubTitle={strSubTitle}
      strColor={strColor}
      strLink={strLink}
    >
      {children}
    </ListCard>
  );
};

const ListCard = ({
  bTask,
  bTop=false,
  strTitle,
  strSubTitle,
  bCompleted,
  strLink,
  funcCompleteTask,
  strColor = 'primary-1',
  children
}) =>
{
  const [bComplete, setBComplete] = React.useState(bCompleted);
  const renderCheck = () =>
  {
    return(
      <a onClick={(e)=>completeTask(e)}>
        {(bComplete) ?
          <i className={`fas fa-2x mb-1 text-${strColor} fa-check-circle`}></i> :
          <i className={`far fa-2x mb-1 text-${strColor} fa-check-circle`}></i>}
      </a>
    );
  };
  const completeTask = () => {
    if(!bComplete){
      setBComplete(true);
      funcCompleteTask();
    }
  }; 
  const renderBtn = () =>
  {
    return(
      <a href={strLink}>
        <i className={`fas fa-2x mb-1 text-${strColor} fa-arrow-right`}></i>
      </a>
    );
  };
  return(
    <div className={`card shadow p-4 custom-border-left-${strColor} border-width-card`}>
      <IF cond={bTop ? NotNullOrUndefined(children) : null}>
        <div className="row">
          <div className="col-md-12">
            {children}
          </div>
        </div>
      </IF>
      <div className="row">
        <div className="col-md-8 my-auto">
          <h5 className="text-dark">{strTitle}</h5>
        </div>
        <div className="col-md-4 text-right">
          {(bTask)? renderCheck() : renderBtn()}
        </div>
      </div>
      <IF cond={!bTop && NotNullOrUndefined(children)}>
        <div className="row">
          <div className="col-md-12">
            {children}
          </div>
        </div>
      </IF>
      <div className="row">
        <div className="col-md-12">
          <span className="text-secondary">{strSubTitle}</span>
        </div>
      </div>
    </div>
  );
};

/**
 * this function formats the quotient in a percentage format
 * @param {number} numNumerator 
 * @param {number} numDenominator 
 * @returns 
 */
export const calcPercent = (numNumerator,numDenominator) =>
{
  return (NotNullOrUndefined(numNumerator,0) > 0 && NotNullOrUndefined(numDenominator,0) > 0) ?
    `${(100 * (numNumerator / numDenominator)).toFixed(1)}%` :
    'N/A'; 
};

/**
 * Returns the percentage for score based on num incorrect and num correct
 * @param {number} numCrt 
 * @param {number} numIncrt 
 * @returns 
 */
export const calcPercentPract = (numCrt, numIncrt) => {
  return calcPercent(numCrt, (numCrt+numIncrt));
};

export const CalendarElm = ({strTitle, strLink}) =>
{
  return(
    <div className="card bg-primary-4 custom-border-left-light border-0 px-2 py-3 my-1 calendar-item raise-hover-sm">
      <div className="row">
        <div className="col-md-9">
          <span className="text-light" style={{fontSize: '85%'}}>{strTitle}</span>
        </div>
        <div className="col-md-3">
          <a href={strLink} >
            <i className="fas fa-lg fa-arrow-circle-right text-light"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

/**
 * Generates a time estimation in minutes for a given practice problem set
 * @param {*} param0 
 * @returns 
 */
export const ProblemSetTimeEst = ({ strName, strSec, strColor, bDefault=false }) => {
  const [numEstMinutes, setNumEstMinutes] = React.useState(bDefault ? 5 : null);
  const TIME_PER_QUES = [36, 60, 52, 52];
  React.useLayoutEffect(() => {
    if(!bDefault) genEstimation();
  }, []);
  const genEstimation = () => {
    let strLink = '';
    let numSecPerQues = 0;
    let numMinutes = 0;
    switch (strSec) {
    case 'English':
      strLink = genPractLnk('Engl', strName, strSec) + '/estimate';
      numSecPerQues = TIME_PER_QUES[0];
      break;
    case 'Math':
      strLink = genPractLnk('Math', strName, strSec) + '/estimate';
      numSecPerQues = TIME_PER_QUES[1];
      break;
    case 'Reading':
      strLink = genPractLnk('Reading', strName, strSec) + '/estimate';
      numSecPerQues = TIME_PER_QUES[2];
      break;
    case 'Science':
      strLink = genPractLnk('Scien', strName, strSec) + '/estimate';
      numSecPerQues = TIME_PER_QUES[3];
      break;
    }
    PostReq(strLink, {})
      .then(data => {
        if (NotNullOrUndefined(data.data.count)) {
          numMinutes = Math.round((data.data.count * numSecPerQues) / 60);
        }
        setNumEstMinutes(numMinutes);
      });
  };
  return (
    <span className={`text-xs text-${strColor} font-weight-bold`}>
      {`${(numEstMinutes > 0) ? `Est. Time: ${numEstMinutes} min.` : ''}`}
    </span>
  );
};

export const BasePageWrapper = ({ bCenterContent=false, children }) => {
  return (
    <div id="content-wrapper" className={bCenterContent ? 'm-auto' : ''}>
      <div id="content">
        {children}
      </div>
    </div>
  );
};

export const IF = ({ cond, children }) => {
  const RenderChildren = () => {
    return cond && children;
  };
  return (
    <>
      {
        cond ?
          RenderChildren():
          <></>
      }
    </>
  );
};

export const RenderArrays = ({ arrElms }) => {
  return (
    <>
      {arrElms.length > 0 ? arrElms : null}
    </>
  );
};

const FIVE_TESTS = ['5AC221', '5AC222',
  '5AC233', '5AC234', '5AC235'];
export const TESTS = ['0359F', '0661C', '0964E', '1267C',
  '1572CPRE', '1874FPRE', '2176CPRE',
  '16MC1', '16MC2', '18MC4', '19MC5',
  '20MC6', '21MC8', ...FIVE_TESTS];
export const [ENGLISH_IND, MATH_IND, READING_IND, SCIENCE_IND] = [0, 1, 2, 3];
export const SECTIONS = ['English', 'Math', 'Reading', 'Science'];
export const convertToSecNm = (section) => {
  switch (section) {
  case SECTIONS[0]:
    return ENGLISH_IND;
  case SECTIONS[1]:
    return MATH_IND;
  case SECTIONS[2]:
    return READING_IND;
  case SECTIONS[3]:
    return SCIENCE_IND;
  }
};
export const GetDecimalRound = (num, den, percision = 2) =>
{
  let val = (num/den).toFixed(percision);
  return isNaN(val) ? 0 : val;
};

export const FIVE_PDF = [
  'mPrTs5AcCcNoRe', 'mPrTs5AcRTwe',
  'mPrTs5AcRThre', 'mPrTs5AcRFou',
  'mPrTs5AcRFiv'
];

export function postDate(yr = 0, mm = 0, dd = 0, hr = 0, min = 0,date=null){
  let d = (date === null)? new Date() : date;
  let year = d.getFullYear();
  let month = d.getMonth();
  let day = d.getDate();
  let hour = d.getHours();
  let mint = d.getMinutes();
  return new Date(year + yr, month + mm, day + dd, hour+hr,mint+min);
}