// Copyright 2021 5 Academy
import React from 'react';
import { BackgroundForSection, StandardVisualTextSection } from '../indexShared';

const WhoAreWeSection = () => {
    return (
        <BackgroundForSection
            strClassName="bg-primary-3 py-4"
            strId="WhoAreWe"
            numMarginTop={0}
        >
            <StandardVisualTextSection
                strImgSrc={'/img/team.png'}
                strImgAlt={'image of test prep team'}
                strImgWidth={'1000px'}
                bTextLeft={false}
                reactElmVisual={null}
                bShadow={false}
            >
                <div className="col-md-8 col-sm-12 float-right text-left text-white font-weight-light">
                    <h4 className="mb-3 font-weight-bold">
                        Who are we?
                    </h4>
                    <p className="mb-4">
                    We’re tutors. Engineers. Nerds. Recent grads. Most importantly, <strong className='font-weight-bold'>we’re here to help</strong>.
                    </p>
                    <p className="mb-4">
                    During our time prepping for the ACT, we developed a plethora of strategies,
                        tips, and methods that helped us improve up to <strong className='font-weight-bold'>12 points overall on the exam</strong>. 
                    </p>
                    <p className="mb-4">
                    We took that experience and coalesced it into the prep course
                        that <strong className='font-weight-bold'>we wish we had access to</strong> in high school, for you!  
                    </p>
                    <div className="mb-4 font-weight-bold">
                        Want to talk to one of us? Just call us at our number 👉🏼 608<p className='d-none'>999</p>62<p className='d-none'>34</p>0581<p className='d-none'>5</p>2<p className='d-none'>a23</p> 
                    </div>
                </div>
            </StandardVisualTextSection>
      </BackgroundForSection>
  );
};

export {
    WhoAreWeSection
}